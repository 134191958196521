import React from "react";

import ImageText from "../components/image-text/ImageText";
import ImageTextOverlay from "../components/image-text-overlay/ImageTextOverlay";
import TextOnBackground from "../components/text-on-background/TextOnBackground";
import ImageCarousel from "../components/image-carousel/ImageCarousel";
import SoloImage from "../components/solo-image/SoloImage";
import Quote from "../components/quote/Quote";
import Map from "../components/map/Map";
import People from "../components/people/People";
import Title from "../components/title/Title";
import Video from "../components/video/Video";
import WebsiteButton from "../components/website-button/WebsiteButton";

export default (name, props) => {
  const components = {
    ImageText: <ImageText {...props} />,
    ImageTextOverlay: <ImageTextOverlay {...props} />,
    TextOnBackground: <TextOnBackground {...props} />,
    ImageCarousel: <ImageCarousel {...props} />,
    SoloImage: <SoloImage {...props} />,
    Quote: <Quote {...props} />,
    Map: <Map {...props} />,
    People: <People {...props} />,
    Title: <Title {...props} />,
    Video: <Video {...props} />,
    WebsiteButton: <WebsiteButton {...props} />
  };

  return components[name];
};
