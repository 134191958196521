import React from "react";
import { Link } from "react-router-dom";

import "./filter-project-preview.scss";

class FilterProjectPreview extends React.Component {
  render() {
    const { data } = this.props;

    return (
      <div className="filter-project-preview">
        <Link to={`/project/${data.slug}`}>
          <img
            src={data.featured_image}
            alt={data.title}
            className="featured-image"
          />
          <div className="details">
            <h2>{data.clients}</h2>
            <h2>{data.title}</h2>
          </div>
        </Link>
      </div>
    );
  }
}

export default FilterProjectPreview;
