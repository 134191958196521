import React from "react";
import { Link, withRouter } from "react-router-dom";

import Logo from "../logo/Logo";
import LanguageSwitcher from "../language-switcher/LanguageSwitcher";
import Hamburger from "../hamburger/Hamburger";
import t from "../../utils/textLookup";
import "./header.scss";

class Header extends React.Component {
  state = {
    hamburgerOpen: false
  };

  componentDidUpdate() {
    window.scrollTo(0,0);
  }

  openMenu() {
    this.setState({
      hamburgerOpen: !this.state.hamburgerOpen
    });
  }

  closeMenu() {
    this.setState({
      hamburgerOpen: false
    });
  }

  render() {
    const { location } = this.props;
    const homeVariant = location.pathname === "/";

    return (
      <header className={homeVariant ? "home" : ""}>
        <Logo homeVariant={homeVariant} />
        <Hamburger
          openMenu={this.openMenu.bind(this)}
          open={this.state.hamburgerOpen}
        />
        <nav className={this.state.hamburgerOpen ? "open" : ""}>
          <ul>
            <Link
              to="/about"
              className={location.pathname === "/about" ? "selected" : ""}
              onClick={this.closeMenu.bind(this)}
            >
              <li>{t("header_about", this.props.language)}</li>
            </Link>
            <Link
              to="/work"
              className={location.pathname === "/work" ? "selected" : ""}
              onClick={this.closeMenu.bind(this)}
            >
              <li>{t("header_work", this.props.language)}</li>
            </Link>
            {/* <Link to="/blog" className={location.pathname === "/blog" ? "selected" : ""}>
              <li>{t("header_blog", this.props.language)}</li>
            </Link> */}
            <Link
              to="/contact"
              className={location.pathname === "/contact" ? "selected" : ""}
              onClick={this.closeMenu.bind(this)}
            >
              <li>{t("header_contact", this.props.language)}</li>
            </Link>
          </ul>
        </nav>
      </header>
    );
  }
}

export default withRouter(Header);
