import React from "react";

import FilterProjectPreview from "../../components/filter-project-preview/FilterProjectPreview";
import t from "../../utils/textLookup";
import { API_URL } from "../../utils/api";

import "./filter-page.scss";

class FilterPage extends React.Component {
  state = {
    projects: [],
    allProjects: [],
    filters: [],
    selectedFilterType: null,
    dropdownOpen: false,
    theme: null
  };

  componentWillMount() {
    // get projects from api
    const contentUrl = `${API_URL}/wp/?post_type=project&lang=${this.props.language.toLowerCase()}`;
    fetch(contentUrl)
      .then(response => response.json())
      .then(data => {
        let selectedFilterType = "typeofwork";

        const projects = data;
        const filters = [];
        projects.forEach(project => {
          if (project[selectedFilterType]) {
            project[selectedFilterType]
              .split(",")
              .forEach(filter => {
                filters.push(filter.trim());
              });
          }
        });

        let initialFilters = [...new Set(filters)];
        if(window.innerWidth <= 540) {
          initialFilters = [];
          selectedFilterType = null;
        }
    
        this.setState({
          filters: initialFilters,
          projects,
          selectedFilterType,
          allProjects: data
        });

        if (this.props.theme) {
          this.initialTheme(this.props.theme, data);
        }
      });
  }

  openDropdown() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  }

  changeFilters(filterOption) {
    const { allProjects } = this.state;

    const projects = allProjects;

    const filters = [];
    projects.forEach(project => {
      if (project[filterOption]) {
        project[filterOption].split(",").forEach(filter => {
          filters.push(filter.trim());
        });
      }
    });

    this.setState({
      filters: [...new Set(filters)],
      selectedFilterType: filterOption,
      dropdownOpen: false,
      theme: null
    });

    this.setState({
      selectedFilter: null,
      projects
    });
  }

  initialTheme(theme, projects) {
    const themeUrl = `${API_URL}/wp/?theme=${theme}`;
    fetch(themeUrl)
      .then(response => response.json())
      .then(data => {
        this.setState({
          theme: data
        });
      })
      .catch(() => {
        this.setState({
          theme: null
        });
      });
    const filters = projects
      .map(project => project.themes)
      .reduce((a, b) => {
        if (a.indexOf(b) < 0) a.push(b);
        return a;
      }, []);

    projects = projects.filter(
      item =>
        item.themes && item.themes.toLowerCase().replace(/ /g, "-") === theme
    );

    this.setState({
      selectedFilterType: "themes",
      selectedFilter: theme,
      filters,
      projects
    });
  }

  chooseFilter(filter) {
    let projects = this.state.allProjects.filter(
      item => item.project_type === this.props.type
    );

    if (this.state.selectedFilterType === "themes") {
      const themeUrl = `${API_URL}/wp/?theme=${filter
        .toLowerCase()
        .replace(/ /g, "-")}`;
      fetch(themeUrl)
        .then(response => response.json())
        .then(data => {
          this.setState({
            theme: data
          });
        })
        .catch(() => {
          this.setState({
            theme: null
          });
        });
    } else {
      this.setState({
        theme: null
      });
    }

    const casedFilter = filter.toLowerCase().replace(/ /g, "-");

    if (casedFilter !== this.state.selectedFilter) {
      projects = projects.filter(
        item =>
          item[this.state.selectedFilterType] &&
          item[this.state.selectedFilterType]
            .toLowerCase()
            .replace(/ /g, "-")
            .indexOf(casedFilter) > -1
      );
      this.setState({
        selectedFilter: casedFilter,
        projects
      });
    } else {
      this.setState({
        selectedFilter: null,
        projects
      });
    }
  }

  render() {
    let projects = "";
    if (this.state.projects) {
      projects = this.state.projects.map((project, index) => {
        const size = index === 0 ? "large" : "medium";
        return <FilterProjectPreview key={project.id} data={project} />;
      });
    }

    return (
      <>
        <div className="page filter-page">
          <h1>{t("title_ourwork")}</h1>
          <div className="filters">
            <div className="select">
              <p onClick={this.openDropdown.bind(this)}>
                {t(`text_${this.state.selectedFilterType}`)}
              </p>
              <div className={this.state.dropdownOpen ? "options open" : "options"}>
                {["typeofwork", "themes", "clients"].map(filterOption => (
                  <p onClick={this.changeFilters.bind(this, filterOption)} className={this.state.selectedFilterType === filterOption ? "option selected" : "option"}>
                    {t(`text_${filterOption}`)}
                  </p>
                ))}
              </div>
            </div>
            <div>
              {this.state.filters.length > 1 &&
                this.state.filters.map(
                  filter =>
                    filter && (
                      <span
                        className={
                          filter.toLowerCase().replace(/ /g, "-") ===
                          this.state.selectedFilter
                            ? "filter selected"
                            : "filter"
                        }
                        onClick={this.chooseFilter.bind(this, filter)}
                      >
                        {filter}
                      </span>
                    )
                )}
              </div>
          </div>

          {this.state.theme && (
            <div className="theme-description">
              <div
                dangerouslySetInnerHTML={{
                  __html: this.state.theme.base_content
                }}
              />
            </div>
          )}

          <section className="filterable-projects">{projects}</section>
        </div>
      </>
    );
  }
}

export default FilterPage;
