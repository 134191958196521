import React from "react";
import { Link } from "react-router-dom";

import "./style-post-preview.scss";

class StylePostPreview extends React.Component {
  render() {
    const { data } = this.props;

    return (
      <div className="style-post-preview">
        <Link to={`/style-edit/${data.id}`}>
          <img
            src={data.featured_image}
            alt={data.title}
            className="featured-image"
          />
          <div className="details">
            <h2>{data.title}</h2>
            <p>{`${data.excerpt.slice(0, 75)}...`}</p>
          </div>
        </Link>
      </div>
    );
  }
}

export default StylePostPreview;
