import React from "react";

import "./image-carousel.scss";

class ImageCarousel extends React.Component {
  state = {
    index: 0,
    actualImages: []
  };

  componentWillMount() {
    const { images } = this.props;
    const actualImages = images.filter(image => image);
    this.setState({ images: actualImages });
  }

  next() {
    const { index, images } = this.state;

    if (index < images.length - 1) {
      this.setState({ index: index + 1 });
    } else {
      this.setState({ index: 0 });
    }
  }

  previous() {
    const { index, images } = this.state;

    if (index > 0) {
      this.setState({ index: index - 1 });
    } else {
      this.setState({ index: images.length - 1 });
    }
  }

  render() {
    const { index, images } = this.state;

    return (
      <div className="image-carousel">
        <div className={`mask size-${images.length} offset-${index}`}>
          {images.map(image => (
            <div className="image-carousel-item">
              <img src={image} />
            </div>
          ))}
        </div>
        {images.length > 2 && (
          <span
            className="controls previous"
            onClick={this.previous.bind(this)}
          />
        )}
        {images.length > 2 && (
          <span className="controls next" onClick={this.next.bind(this)} />
        )}
      </div>
    );
  }
}

export default ImageCarousel;
