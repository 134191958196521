import React from "react";

import "./title.scss";

const Title = ({ text }) => (
  <div className="title-component">
    <h2>{text}</h2>
  </div>
);

export default Title;
