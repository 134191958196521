import React from "react";

import "./text-on-background.scss";

const TextOnBackground = ({
  image,
  text_content,
  image_position,
  background_colour
}) => (
  <div className={`text-on-background bg-${background_colour.toLowerCase()}`}>
    <div
      dangerouslySetInnerHTML={{ __html: text_content }}
      className="content"
    />
  </div>
);

export default TextOnBackground;
