import React from "react";
import { Map, GoogleApiWrapper, Marker } from "google-maps-react";

import mapPin from "../../assets/pin.png";
import "./map.scss";
import mapStyles from "./mapStyles";

const MapContainer = ({ text_content, google }) => (
  <div className="map-component">
    <div className="map-container">
      <Map
        google={google}
        initialCenter={{
          lat: 51.52589,
          lng: -0.08519
        }}
        zoom={16}
        styles={mapStyles}
        fullscreenControl={false}
        mapTypeControl={false}
        streetViewControl={false}
      >
        <Marker
          position={{
            lat: 51.52589,
            lng: -0.08519
          }}
          icon={{
            url: mapPin
          }}
        />
      </Map>
    </div>
    <div className="content-container">
      <div
        dangerouslySetInnerHTML={{ __html: text_content }}
        className="content"
      />
    </div>
  </div>
);

export default GoogleApiWrapper({
  apiKey: "AIzaSyBG4NFsAcBqOKBbdJ8lhMQQ9Ud1QTEHuMg"
})(MapContainer);
