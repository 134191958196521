import React from "react";

import "./image-text.scss";

const ImageText = ({
  image,
  text_content,
  image_position,
  background_colour
}) => (
  <div className={`image-text-component ${image_position.toLowerCase()}`}>
    <div>
      <img src={image} />
    </div>
    <div className={`bg-${background_colour.toLowerCase()}`}>
      <div
        dangerouslySetInnerHTML={{ __html: text_content }}
        className="content"
      />
    </div>
  </div>
);

export default ImageText;
