import React from "react";
import { Link } from "react-router-dom";

import t from "../../utils/textLookup";
import "./homepage-project-preview.scss";

class HomepageProjectPreview extends React.Component {
  render() {
    const { data, size, language } = this.props;

    return (
      <div className={`homepage-project-preview-${size}`}>
        <Link to={`/project/${data.slug}`}>
          <img src={data.homepage_image} alt={data.title} />
          <div
            className={`details details-${data.homepage_banner_text_colour}`}
          >
            <h2>
              <span className="blink">{data.homepage_title}</span>
            </h2>
            <div className="excerpt">
              <div className="blink">
                <div
                  dangerouslySetInnerHTML={{ __html: data.homepage_excerpt }}
                />
              </div>
            </div>
          </div>
        </Link>
      </div>
    );
  }
}

export default HomepageProjectPreview;
