import React from "react";

import ProjectDetails from "../../components/project-details/ProjectDetails";
import down from "../../assets/down.png";
import "./project-introduction.scss";

class ProjectIntroduction extends React.Component {
  scrollDown() {
    window.scroll({ top: window.innerHeight, left: 0, behavior: "smooth" });
  }

  render() {
    const { data } = this.props;
    return (
      <div className="project-intro">
        <div className="details">
          <h1>
            {data.clients}
            <br />
            {data.title}
          </h1>
          <ProjectDetails data={data} />
        </div>
      </div>
    );
  }
}

export default ProjectIntroduction;
