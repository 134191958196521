import React from "react";
import { Link } from "react-router-dom";

import instagram from "../../assets/instagram.png";
import twitter from "../../assets/twitter.png";
import linkedin from "../../assets/linkedin.png";
import t from "../../utils/textLookup";
import "./footer.scss";

class Footer extends React.Component {
  render() {
    const { language } = this.props;
    return (
      <footer>
        <div className="content">
          <p className="address">
            <span>{t("howoco_address", language)}</span>
            <span>
              <a href={`mailto:${t("howoco_email")}`}>{t("howoco_email")}</a>
            </span>
          </p>
          <div className="social">
            <span>
              <a href="https://www.instagram.com/howocostudio/" target="_blank">
                <img src={instagram} alt={t("instagram", language)} />
              </a>
              <a href="https://twitter.com/howoco" target="_blank">
                <img src={twitter} alt={t("twitter", language)} />
              </a>
              <a href="https://www.linkedin.com/company/howoco" target="_blank">
                <img src={linkedin} alt={t("linkedin", language)} />
              </a>
            </span>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
