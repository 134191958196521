import content from "../assets/content";

export const textKeyExists = (key, language = "EN") =>
  Object.hasOwnProperty.call(content[language], key);

export default (key, language = "EN") => {
  if (!textKeyExists(key, language)) {
    console.warn(`static content key '${key}' does not exist`);
    return "";
  }
  return content[language][key];
};
