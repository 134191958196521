import React from "react";

import "./video.scss";

export default class Video extends React.Component {
  parseVideo(url) {
    url.match(/(http:|https:|)\/\/(player.|www.)?(vimeo\.com|youtu(be\.com|\.be|be\.googleapis\.com))\/(video\/|embed\/|watch\?v=|v\/)?([A-Za-z0-9._%-]*)(\&\S+)?/);
    let type;
    if (RegExp.$3.indexOf('youtu') > -1) {
      type = 'youtube';
    } else if (RegExp.$3.indexOf('vimeo') > -1) {
      type = 'vimeo';
    }

    return {
      type,
      id: RegExp.$6
    };
  }

  createVideo(url) {
    let videoObj = this.parseVideo(url);
    if (videoObj.type === 'youtube') {
      return <iframe src={`//www.youtube.com/embed/${videoObj.id}`} title="" />;
    } else if (videoObj.type === 'vimeo') {
      return <iframe src={`//player.vimeo.com/video/${videoObj.id}`} title="" />;
    }
  }

  /*getVideoThumbnail(url, cb) {
    // Obtains the video's thumbnail and passed it back to a callback function.
    var videoObj = parseVideo(url);
    if (videoObj.type == 'youtube') {
      cb('//img.youtube.com/vi/' + videoObj.id + '/maxresdefault.jpg');
    } else if (videoObj.type == 'vimeo') {
      // Requires jQuery
      $.get('http://vimeo.com/api/v2/video/' + videoObj.id + '.json', function (data) {
        cb(data[0].thumbnail_large);
      });
    }
  }*/

  render() {
    const {video_url, caption, caption_colour} = this.props;
    return (<div className="video">
        <div className="video-container">
          {this.createVideo(video_url)}
        </div>
        {caption && (
          <div
            className={`caption ${caption_colour}`}
            dangerouslySetInnerHTML={{__html: caption}}
          />
        )}
      </div>
    );
  }
}
