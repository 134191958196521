import React from "react";

import "./people.scss";

const People = ({ image, text_content, name, title, honorifics }) => (
  <div className="people-component">
    <div>
      <img src={image} />
    </div>
    <div>
      <h2>{name}</h2>
      <h3>{title}</h3>
      <h3>{honorifics}</h3>
      <div
        dangerouslySetInnerHTML={{ __html: text_content }}
        className="content"
      />
    </div>
  </div>
);

export default People;
