export default {
  EN: {
    howoco: "Howoco",
    howoco_address: "Howoco, Albert House, Old Street, London EC1V 9DD",
    howoco_tel: "+44(0)203 195 2727",
    howoco_email: "hello@howoco.com",

    instagram: "Instagram",
    twitter: "Twitter",
    linkedin: "LinkedIn",

    header_about: "About Howoco",
    header_work: "Our work",
    header_blog: "Blog",
    header_contact: "Contact us",

    text_typeofwork: "Type of work",
    text_themes: "Themes",
    text_clients: "Clients",
    text_project_description: "Project",
    text_project_platform: "Plaform",
    text_project_client: "Client",
    text_visit_website: "Visit website",
    text_previous: "Previous",
    text_next: "Next",
    text_backtotop: "Back to top",

    title_ourwork: "Our work",
    title_contactus: "Contact us"
  }
};
