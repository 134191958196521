import React from 'react';

import t from '../../utils/textLookup';

const WebsiteButton = ({ project_website }) => (
  <a
    href={project_website}
    target="_blank"
    className="website-button"
  >
      {t("text_visit_website")}
  </a>
);

export default WebsiteButton;