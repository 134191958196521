import React from "react";

import "./quote.scss";

const Quote = ({ image, quotation, quotee }) => (
  <div className="quote">
    <img src={image} />
    <div className="quote-content">
      <h3>&ldquo;{quotation}&rdquo;</h3>
      <p>{quotee}</p>
    </div>
  </div>
);

export default Quote;
