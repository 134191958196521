import React from "react";

import HomepageProjectPreview from "../../components/homepage-project-preview/HomepageProjectPreview";
import { API_URL } from "../../utils/api";
import "./homepage.scss";

class Homepage extends React.Component {
  state = {};

  componentWillMount() {
    // get projects from api
    const contentUrl = `${API_URL}/wp/?post_type=project&lang=${this.props.language.toLowerCase()}`;
    fetch(contentUrl)
      .then(response => response.json())
      .then(data => {
        this.setState({
          projects: data
            .filter(item => item.show_on_homepage && item.homepage_order)
            .sort(
              (a, b) => parseInt(a.homepage_order) > parseInt(b.homepage_order)
            )
        });

        const homepageContentUrl = `${API_URL}/wp/?post_type=homepage&lang=${this.props.language.toLowerCase()}`;
        fetch(homepageContentUrl)
          .then(response => response.json())
          .then(data => {
            this.setState({
              homepageBanners: data[0].banners
            });
          });
      });
  }

  render() {
    let projects = "";

    if (this.state.projects) {
      projects = this.state.projects
        .sort((a, b) => (a.homepage_order > b.homepage_order ? 1 : -1))
        .map((project, index) => {
          const size = index === 0 ? "large" : "medium";
          return (
            <HomepageProjectPreview
              key={project.id}
              data={project}
              size={size}
              language={this.props.language}
            />
          );
        });
    }

    const { language } = this.props;

    return (
      <>
        <section className="projects">{projects}</section>
      </>
    );
  }
}

export default Homepage;
