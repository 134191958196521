import React from "react";

import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import componentLookup from "../../utils/componentLookup";
import { API_URL } from "../../utils/api";

class ProjectPage extends React.Component {
  state = {
    data: {}
  };

  componentWillMount() {
    // get props from api
    const { language } = this.props;

    const contentUrl = `${API_URL}/wp/?page_id=${this.props.pageId}`;
    fetch(contentUrl)
      .then(response => response.json())
      .then(data => {
        if (language !== "EN") {
          fetch(data.languages[language.toLowerCase()])
            .then(response => response.json())
            .then(translatedData => {
              this.setState({
                data: translatedData
              });
            });
        } else {
          this.setState({
            data
          });
        }
      });
  }

  render() {
    const { data } = this.state;

    return (
      <>
        <div class="page">
          {data.components &&
            data.components.map(component => {
              return componentLookup(component.type, component);
            })}
        </div>
      </>
    );
  }
}

export default ProjectPage;
