import React from "react";

import ProjectIntroduction from "../../components/project-introduction/ProjectIntroduction";
import ProjectNavigation from "../../components/project-navigation/ProjectNavigation";
import t from "../../utils/textLookup";
import componentLookup from "../../utils/componentLookup";
import { API_URL } from "../../utils/api";
import "./project-page.scss";

class ProjectPage extends React.Component {
  state = {
    data: {}
  };

  componentWillMount() {
    // get props from api
    const { language } = this.props;

    const contentUrl = `${API_URL}/wp/?project=${this.props.location.pathname.replace(
      "/project/",
      ""
    )}`;
    fetch(contentUrl)
      .then(response => response.json())
      .then(data => {
        if (language !== "EN") {
          fetch(data.languages[language.toLowerCase()])
            .then(response => response.json())
            .then(translatedData => {
              this.setState({
                data: translatedData
              });
            });
        } else {
          this.setState({
            data
          });
        }
      });
  }

  render() {
    const { data, language } = this.state;

    return (
      <>
        <ProjectIntroduction
          title={data.title}
          excerpt={data.excerpt}
          data={data}
        />
        {data.components &&
          data.components.map(component => {
            return componentLookup(component.type, component);
          })}
        <ProjectNavigation next={data.next} previous={data.previous} />
      </>
    );
  }
}

export default ProjectPage;
