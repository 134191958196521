import React from "react";
import { Link } from "react-router-dom";

import t from "../../utils/textLookup";
import nextProject from "../../assets/next-project.png";
import previousProject from "../../assets/previous-project.png";
import "./project-navigation.scss";

class ProjectNavigation extends React.Component {
  scrollToTop() {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
  }

  render() {
    const { next, previous } = this.props;

    return (
      <div className="project-navigation">
        <div>
          {previous && (
            <Link to={`/project/${previous}`} onClick={this.scrollToTop.bind(this)}>
              <img src={previousProject} />
            </Link>
          )}
        </div>
        <div>
          <p onClick={this.scrollToTop.bind(this)}>{t("text_backtotop")}</p>
        </div>
        <div>
          {next && (
            <Link to={`/project/${next}`} onClick={this.scrollToTop.bind(this)}>
              <img src={nextProject} />
            </Link>
          )}
        </div>
      </div>
    );
  }
}

export default ProjectNavigation;
