import React from "react";

import "./project-details.scss";
import t from "../../utils/textLookup";

const ProjectDetails = ({ data, language }) => (
  <div className="project-details">
    <div>
      {data.project_description && (
        <div>
          <h3>{t("text_project_description", language)}</h3>
          <p>{data.project_description}</p>
        </div>
      )}
      {data.project_platform && (
        <div>
          <h3>{t("text_project_platform", language)}</h3>
          <p>{data.project_platform}</p>
        </div>
      )}
      {data.project_client && (
        <div>
          <h3>{t("text_project_client", language)}</h3>
          <p>{data.project_client}</p>
        </div>
      )}
    </div>
    <div>
      <p>{data.project_paragraph}</p>
    </div>
  </div>
);

export default ProjectDetails;
