import React from "react";

import t from "../../utils/textLookup";
import "./language-switcher.scss";

class LanugageSwitcher extends React.Component {
  onClick() {
    const newLanguage = this.props.language === "EN" ? "ZH" : "EN";
    window.localStorage.setItem("language", newLanguage);
    window.location.reload();
  }

  render() {
    return (
      <span className="language-switcher" onClick={this.onClick.bind(this)}>
        {t("header_changeLanguage", this.props.language)}
      </span>
    );
  }
}

export default LanugageSwitcher;
