import React from "react";
import { Link } from "react-router-dom";

import logoImg from "../../assets/logo.png";
import logoVariantImg from "../../assets/logo-variant.png";
import "./logo.scss";
import t from "../../utils/textLookup";

const Logo = ({ language, homeVariant }) => (
  <Link className="logo" to="/">
    <img
      src={homeVariant ? logoVariantImg : logoImg}
      alt={t("howoco", language)}
    />
  </Link>
);

export default Logo;
