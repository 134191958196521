import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import Homepage from "./containers/homepage/Homepage";
import FilterPage from "./containers/filter-page/FilterPage";
import ProjectPage from "./containers/project-page/ProjectPage";
import DynamicPage from "./containers/dynamic-page/DynamicPage";
import ContactPage from "./containers/contact-page/ContactPage";
import StylePost from "./containers/style-post/StylePost";
import StyleEditIndexPage from "./containers/style-edit-index-page/StyleEditIndexPage";
import { API_URL } from "./utils/api";

class App extends Component {
  language = "EN";

  state = {
    projectPages: [],
    stylePosts: [],
    themePages: []
  };

  componentWillMount() {
    // get list of pages and render routes

    const projectsUrl = `${API_URL}/wp/?post_type=project`;
    fetch(projectsUrl)
      .then(response => response.json())
      .then(data => {
        this.setState({
          projectPages: data.map(projectPage => (
            <Route
              exact
              key={projectPage.id}
              path={`/project/${projectPage.slug}`}
              component={props => (
                <ProjectPage language={this.language} {...props} />
              )}
            />
          ))
        });
      });

    const themesUrl = `${API_URL}/wp/?post_type=theme`;
    fetch(themesUrl)
      .then(response => response.json())
      .then(data => {
        this.setState({
          themePages: data.map(themePage => (
            <Route
              exact
              key={themePage.id}
              path={`/theme/${themePage.slug}`}
              component={props => (
                <FilterPage language={this.language} theme={themePage.slug} />
              )}
            />
          ))
        });
      });

    const stylePostsUrl = `${API_URL}/wp/?post_type=post`;
    fetch(stylePostsUrl)
      .then(response => response.json())
      .then(data => {
        this.setState({
          stylePosts: data.map(stylePost => (
            <Route
              exact
              key={stylePost.id}
              path={`/style-edit/${stylePost.id}`}
              component={props => (
                <StylePost language={this.language} {...props} />
              )}
            />
          ))
        });
      });

    if (localStorage.getItem("language")) {
      this.language = localStorage.getItem("language");
    } else {
      localStorage.setItem("language", this.language);
    }
  }

  componentDidUpdate() {
    this.language = localStorage.getItem("language");
  }

  render() {
    if (!this.state.projectPages.length) {
      return "Loading...";
    }

    return (
      <BrowserRouter basename="/">
        <div>
          <Header language={this.language} />
          <div className="page">
            <Switch>
              <Route
                exact
                path="/"
                component={() => <Homepage language={this.language} />}
              />
              {this.state.projectPages}
              {this.state.themePages}
              <Route
                exact
                path="/about"
                component={() => (
                  <DynamicPage
                    language={this.language}
                    type="Commercial"
                    pageId="33"
                  />
                )}
              />
              <Route
                exact
                path="/dhc"
                component={() => (
                  <DynamicPage language={this.language} type="Commercial" pageId="541" />
                )}
              />
              <Route
                exact
                path="/contact"
                component={() => (
                  <ContactPage language={this.language} pageId="38" />
                )}
              />
              <Route
                exact
                path="/work"
                component={() => <FilterPage language={this.language} />}
              />
              <Route
                exact
                path="/blog"
                component={() => (
                  <StyleEditIndexPage language={this.language} />
                )}
              />
              {this.state.stylePosts}

              <Route component={() => <h1>404</h1>} />
            </Switch>
          </div>
          <Footer language={this.language} />
        </div>
      </BrowserRouter>
    );
  }
}

export default App;
