import React, { Component } from "react";

import "./solo-image.scss";

class SoloImage extends Component {
  state = {
    image: window.innerWidth <= 540 && this.props.mobile_image ? this.props.mobile_image : this.props.image
  }

  componentWillMount() {
    this.updateDimensions.bind(this);
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions.bind(this));
  }

  updateDimensions() {
    if(window.innerWidth <= 540 && this.props.mobile_image) {
      this.setState({
        image: this.props.mobile_image
      });
    } else {
      this.setState({
        image: this.props.image
      });
    }
  }

  render() {
    const { caption, caption_colour } = this.props;
    const { image } = this.state;
    return (
      <div className="solo-image">
        <img src={image} />
        {caption && (
          <div
            className={`caption ${caption_colour}`}
            dangerouslySetInnerHTML={{ __html: caption }}
          />
        )}
      </div>
    );
  }
}

export default SoloImage;
