import React from "react";

import StylePostPreview from "../../components/style-post-preview/StylePostPreview";
import t from "../../utils/textLookup";
import { API_URL } from "../../utils/api";

import "./style-edit-index-page.scss";

class StyleEditIndexPage extends React.Component {
  state = {};

  componentWillMount() {
    // get posts from api
    const contentUrl = `${API_URL}/wp/?post_type=post&lang=${this.props.language.toLowerCase()}`;
    fetch(contentUrl)
      .then(response => response.json())
      .then(data => {
        this.setState({
          stylePosts: data
        });
      });
  }

  render() {
    let stylePosts = "";
    if (this.state.stylePosts) {
      stylePosts = this.state.stylePosts.map((stylePost, index) => {
        return <StylePostPreview key={stylePost.id} data={stylePost} />;
      });
    }

    return (
      <>
        <section className="filterable-posts">
          <div className="intro">
            <h1>{t("text_styleEdit", this.props.language)}</h1>
            <h3>{t("text_styleEditDesc", this.props.language)}</h3>
          </div>

          <div className="masonry">{stylePosts}</div>
        </section>
      </>
    );
  }
}

export default StyleEditIndexPage;
