import React from "react";

import "./image-text-overlay.scss";

const ImageTextOverlay = ({
  image,
  text_content,
  textbox_position,
  background_colour
}) => (
  <div
    className={`image-text-overlay-component ${textbox_position.toLowerCase()}`}
  >
    <img src={image} />
    <div className={`textbox bg-${background_colour.toLowerCase()}`}>
      <div
        dangerouslySetInnerHTML={{ __html: text_content }}
        className="content"
      />
    </div>
  </div>
);

export default ImageTextOverlay;
