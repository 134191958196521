import React from "react";
import { Link } from "react-router-dom";

import t from "../../utils/textLookup";
import componentLookup from "../../utils/componentLookup";

import next from "../../assets/next.png";
import previous from "../../assets/previous.png";
import "./style-post.scss";
import { API_URL } from "../../utils/api";

class StylePost extends React.Component {
  state = {
    data: {}
  };

  componentWillMount() {
    // get props from api
    const { language } = this.props;

    const contentUrl = `${API_URL}/wp/?p=${this.props.location.pathname.replace(
      "/style-edit/",
      ""
    )}`;
    fetch(contentUrl)
      .then(response => response.json())
      .then(data => {
        if (language !== "EN") {
          fetch(data.languages[language.toLowerCase()])
            .then(response => response.json())
            .then(translatedData => {
              this.setState({
                data: translatedData
              });
            });
        } else {
          this.setState({
            data
          });
        }
      });
  }

  scrollToTop() {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
  }

  render() {
    const { data } = this.state;
    return (
      <>
        <div className="style-post">
          <div className="style-post-content">
            <h2>{t("text_styleEdit", this.props.language)}</h2>
            <h1>{data.title}</h1>
            {data.components &&
              data.components.map(component => {
                return componentLookup(component.type, component);
              })}
            <div className="navigation">
              <span>
                {data.previous && (
                  <Link to={`/style-edit/${data.previous}`}>
                    <img src={previous} alt="Previous project" />
                  </Link>
                )}
              </span>
              <span>
                <a onClick={this.scrollToTop}>{t("text_backToTop")}</a>
              </span>
              <span>
                {data.next && (
                  <Link to={`/style-edit/${data.next}`}>
                    <img src={next} alt="Next project" />
                  </Link>
                )}
              </span>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default StylePost;
