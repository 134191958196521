import React from "react";

import "./contact-page.scss";
import DynamicPage from "../dynamic-page/DynamicPage";
import t from "../../utils/textLookup";
import componentLookup from "../../utils/componentLookup";

class ContactPage extends DynamicPage {
  render() {
    const { data } = this.state;
    console.log(this.props.location);

    return (
      <>
        <div class="page contact-page">
          <h1>{t("title_contactus")}</h1>
          {data.components &&
            data.components.map(component => {
              return componentLookup(component.type, component);
            })}
        </div>
      </>
    );
  }
}

export default ContactPage;
