import React from "react";

import "./hamburger.scss";

class Hamburger extends React.Component {
  render() {
    const { openMenu, open } = this.props;

    return (
      <span
        className={open ? "hamburger open" : "hamburger"}
        onClick={openMenu}
      >
        <div className="line line1" />
        <div className="line line2" />
        <div className="line line3" />
      </span>
    );
  }
}

export default Hamburger;
